@keyframes jump {
    0% {
      transform: translateY(0px);
    }
    30% {
      transform: translateY(0px);
      animation-timing-function: ease-out;
    }
    50% {
      transform: translateY(-200%);
      animation-timing-function: ease-in;
    }
    75% {
      transform: translateY(0px);
      animation-timing-function: ease-in;
    }
  }
  
  @keyframes morph {
    0% {
      transform: scaleY(1);
    }
    10% {
      transform: scaleY(1);
    }
    20%,
    25% {
      transform: scaleY(0.6) scaleX(1.3);
      animation-timing-function: ease-in-out;
    }
    30% {
      transform: scaleY(1.15) scaleX(0.9);
      animation-timing-function: ease-in-out;
    }
    40% {
      transform: scaleY(1);
    }
    70%,
    85%,
    100% {
      transform: scaleY(1);
    }
    75% {
      transform: scaleY(0.8) scaleX(1.2);
    }
  }
  