.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.container {
  position: relative;
}

.page {
  position: absolute;
  left: 0px;
  right: 0px;
}

.page-enter {
  opacity: 0.1;
}

.page-enter-active {
  opacity: 1;
  transition: opacity 800ms, transform 800ms;
  transition-timing-function: linear;
  position: absolute;
  z-index: 10;
}

.page-exit {
  opacity: 1;
}

.page-exit-active {
  opacity: 0;
  transition: opacity 800ms, transform 800ms;
  transition-timing-function: linear;
  position: absolute;
  z-index: 5;
}

.CircularProgressbar-text {
  font-weight: 500;
}

.customBg{
  background: #000513 url(../src/assets//imgs/BG-new.svg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  overflow-y: auto;
}

.home-card-bg{
  background-image: url(../src/assets/imgs/home-chat-card-bg.svg);
  background-repeat: no-repeat;
  background-position: top;
}

.capabilities-bg{
  background: #000513 url(../src/assets//imgs/BG.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

@media (max-width: 600px) {
  .customBg{
    background: transparent;
  }

  .home-card-bg{
    background-image: url(../src/assets/imgs/home-chat-card-bg-2.svg);
  }

  .capabilities-bg{
    background: rgba(24, 26, 32, 0.50);
    backdrop-filter: blur(22.25px);
  }
}

.chatbot-header-text{
  background: linear-gradient(90deg, #3858C2 0%, #4ED1FA 100%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
}

.chatbot-bot-bg{
   background: linear-gradient(180deg, #3EE5FF 15.41%, #0048FF 198.37%, #060A1F 383.39%);
   filter: blur(70px); 
   -webkit-transform:translate3d(0,0,0);
}

.bg-clip-text {
  background: linear-gradient(90deg, #0048FF 0%, #3EE5FF 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

}

.scrollToBottom > button{
  display: none;
}

.grounded-radiants {
  background: 
  linear-gradient(#011235 0 0) padding-box,
  linear-gradient(90deg, #05D883, #2861FD, #BB4CFF) border-box;
color: #313149;
border: 4px solid transparent;
border-radius: 15px;
display: inline-block;
}

.selectDisable {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  pointer-events: none;

}

.bg-blur::before {
  background-color: #ffffff13;
}

.bg-blur {
  position: relative;
  background-color: #FFFFFF1A;
  overflow: hidden;
}

.bg-blur::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  filter: 20px;
  -webkit-transform:translate3d(0,0,0);
  z-index: -1;
}


.grayBtn{
  transition: all;
  transition-duration: 300ms;
  background-color: #ffffff1a;
}

.grayBtn:active{
  background-color: #ffffff4d;
}

.no-select {
  user-select: none;
  -webkit-user-select: none; 
  -ms-user-select: none;
}

img {
  pointer-events: none;
}